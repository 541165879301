import React, { useState, useEffect } from "react"
import Header from "./components/Header"
import Hero from "./components/Hero"
import ProductGrid from "./components/ProductGrid"
import "./styles.css"

const App = () => {
  const [merchantData, setMerchantData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search)
        const merchantCode = urlParams.get("code")
        if (merchantCode) {
          setMerchantData({
            merchantCode,
            merchantName: "Default Merchant Name",
            productUrls: {
              UMPP_Application_URL: "#",
              NMPP_Application_URL: "#",
              GTT_Application_URL: "#",
            },
          })
        } else {
          throw new Error("No merchant code provided")
        }
      } catch (error) {
        setError(error.message)
        setMerchantData({
          merchantCode: "ERROR",
          merchantName: "Error Loading Merchant",
          productUrls: {
            UMPP_Application_URL: "#",
            NMPP_Application_URL: "#",
            GTT_Application_URL: "#",
          },
        })
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  if (loading) {
    return <div className="loading">Loading...</div>
  }

  if (error) {
    return <div className="error">Error: {error}</div>
  }

  return (
    <div className="app">
      <Header />
      <Hero
        merchantName={merchantData.merchantName}
        merchantCode={merchantData.merchantCode}
      />
      <ProductGrid productUrls={merchantData.productUrls} />
    </div>
  )
}

export default App
